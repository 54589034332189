<template>
  <div class="eula contentWrapper" v-bind:class="{ themeBlack: theme ==='black' }" >
    <h1>EULA</h1>

    <div class="content">
<h3>END USER LICENSE AGREEMENT<br>For the NaturalPoint TrackIR product</h3>

<p>NaturalPoint, Inc. licenses this software product to you subject to the terms contained in this end user license agreement ("EULA"). Read the terms of this EULA carefully. By installing, copying or otherwise using the product (as defined further in the license body), you agree to be bound by the terms of this EULA. If you do not agree to the terms of this EULA, do not install, copy or use the product.</p>

<h3>NOTICE TO CUSTOMER</h3>
<p>
    If you do not agree to the terms of this EULA, do not install, or use this software. This EULA is a contract between you (either an individual or an entity) and NaturalPoint, Inc. ("NaturalPoint"), which governs your use of the software product that accompanies this EULA and related software components, which may include associated media, printed materials, and electronic documentation.</p>

<h3>DEFINITIONS</h3>
<p>
    This software package includes a TrackIR software product. The TrackIR software product is composed of a proprietary Application, Driver, and dll components, In this EULA, the TrackIR Application, related components, associated media, printed materials, and electronic documentation are collectively referred to as the "Software."</p>

<p>The NaturalPoint TrackIR hardware is composed of a physical TrackIR product manufactured by NaturalPoint, Inc and will be referred to as "Hardware".</p>

<p>THE SOFTWARE IS PROVIDED TO YOU "AS IS" WITHOUT WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. NATURALPOINT BEARS NO LIABILITY FOR ANY DAMAGES RESULTING FROM USE (OR ATTEMPTED USE) OF THE SOFTWARE THROUGH AND AFTER THE EXPIRATION DATE, AND HAS NO DUTY TO PROVIDE SUPPORT TO YOU.</p>

<h3>LICENSE</h3>
<p>
    The Software is licensed, not sold. Subject to the terms and limitations of this EULA, NaturalPoint hereby grants you a nonexclusive, nontransferable license, without rights to sublicense, to use the TrackIR software ONLY with NaturalPoint TrackIR Hardware. Use of the TrackIR software with other hardware, hardware manufactured by other parties, or anything which emulates a TrackIR is prohibited.</p>

<h3>LICENSE LIMITATIONS</h3>
<p>
    You may not copy the Software except for a reasonable number of machine-readable copies of the Software for backup or archival purposes and except as expressly permitted in the License section above.You may not remove any titles, trademarks or trade names, copyright notices, legends, or other proprietary markings on the Software. You are not granted any rights to any trademarks or service marks of NaturalPoint. NaturalPoint retains all rights not expressly granted to you.</p>

<h3>PROPRIETARY RIGHTS RESERVED BY NATURALPOINT</h3>
<p>
    NaturalPoint retains all right, title, and interest in and to the Software and the Software License Key and inall related copyrights, trade secrets, patents, trademarks, and any other intellectual and industrial propertyand proprietary rights, including registrations, applications, renewals, and extensions of such rights.</p>

<h3>RESTRICTIONS</h3>
<p>
    You may not (i) sell, lease, license, sublicense, distribute or otherwise transfer in whole or in part the Software to another party; (ii) provide, disclose, divulge or make available to, or permit use of the Software in whole or in part by, any third party without NaturalPoints's prior written consent; (iii) modify or create derivative works based upon the Software; or (iv)  to the extent that NaturalPoint is permitted by applicable law to exclude or limit the following rights, you may not decompile, disassemble, reverse engineer, or otherwise attempt to derive source code from the Software, in whole or in part. You may not disclose the results of any benchmark test of the Software to any third party without NaturalPoint's prior written approval.</p>

<h3>TERMINATION</h3>
<p>
    NaturalPoint may terminate this EULA if you fail to comply with any term of this EULA. In the event of termination, you must destroy all copies of the Software. In addition you must remove all copies of the Software from all computers on which it is installed.</p>

<h3>GOVERNMENT RESTRICTIONS</h3>
<p>
    You may not export or re-export the Software except in compliance with the United States Export Administration Act and the related rules and regulations and similar non-U.S. government restrictions, if applicable. The Software and accompanying documentation are deemed to be "commercial computer software" and "commercial computer software documentation," respectively, pursuant to DFAR Section 227.7202 and FAR Section 12.212(b), as applicable. Any use, modification, reproduction, release, performing, displaying, or disclosing of the Software by the U.S. Government shall be governed solely by the terms of this EULA.</p>

<h3>LIMITED WARRANTY</h3>
<p>
    NaturalPoint warrants that the media, if any, on which the Software is delivered will be free of defects and that the Software will substantially conform to the description contained in the applicable end user documentation in each case for a period of 90 days after the date of shipment of the TrackIR Software. EXCEPT FOR THE PRECEDING EXPRESS LIMITED WARRANTY, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NaturalPoint PROVIDES THE SOFTWARE WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, STATUTORY, OR IN ANY OTHER PROVISION OF THIS EULA OR COMMUNICATION WITH YOU, AND NATURALPOINT SPECIFICALLY DISCLAIMS ANYIMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</p>

<h3>LIMITATION OF LIABILITY</h3>
<p>
    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL NATURALPOINTBE LIABLE FOR ANY LOST PROFITS OR BUSINESS OPPORTUNITIES, LOSS OF USE, BUSINESS INTERRUPTION, LOSS OF DATA, OR ANY OTHER INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES UNDER ANY THEORY OF LIABILITY, WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, PRODUCT LIABILITY, OR OTHERWISE. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE PRECEDING LIMITATION MAY NOT APPLY TO YOU.</p>

<p>NATURALPOINT'S LIABILITY UNDER THIS EULA WILL NOT, IN ANY EVENT, EXCEED THE LICENSE FEES, IF ANY, PAID BY YOU TO NATURALPOINT FOR THE SOFTWARE LICENSED BY YOU UNDER THIS EULA.</p>

<p>THE FOREGOING LIMITATIONS SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, REGARDLESS OF WHETHER NATURALPOINT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER ANY REMEDY FAILS OF ITS ESSENTIAL PURPOSE.</p>

<h3>PUBLIC REFERENCE</h3>
<p>
    Licensee consents to the public use of its personal and/or corporate name as a customer of NaturalPoint, unless Licensee notifies NaturalPoint in writing that it withholds such consent.</p>

<h3>GENERAL</h3>
<p>
    This EULA is governed by the laws of the State of Oregon and the United States of America, without regard to conflict of law principles. The United Nations Convention for the International Sale of Goods shall not apply. This EULA is the entire agreement between us and supersedes the terms of any purchaseorders and any other communications or advertising with respect to the Software. If any provision of this EULA is held invalid, the remainder of this EULA shall continue in full force and effect. This EULA may be
modified only by written agreement signed by authorized representatives of you and NaturalPoint.</p>

<h3>CONTACT INFORMATION</h3>
<p>
    If you have any questions about this EULA, or if you want to contact NaturalPoint for any reason, please visit our websites for current contact information at : <a target="_blank" href="https://www.naturalpoint.com">www.naturalpoint.com</a></p>

<p>NaturalPoint and TrackIR are trademarks of NaturalPoint, Inc.</p>
    </div>
  </div>
  <!-- END eula -->
</template>
<script>
export default {
  name: "Eula",
  props: ["theme"]
};
</script>
<style lang="scss" scoped>
.eula {
  text-align: center;

  h1 ~ p {
    margin-top: 0px;
    margin-bottom: 45px;
  }

  h3 {
    margin-bottom: 0;
    color: #ffffff;
    font-weight: 700;
  }

  h3 ~ p {
    margin-top: 0px;
  }

  .content {
    text-align: left;
    padding-bottom: 40px;
  }

  &.themeBlack {
    color:#666666;

    h1,h2,h3 {
      color: #000;
    }
    a {
      color:#000;
      text-decoration: underline;
    }
  }
}
</style>